import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Image from 'gatsby-image';
import SocialBar from './social-bar';

const Bio = () => {
  const data = useStaticQuery(graphql`
      query BioQuery {
          avatar: file(absolutePath: { regex: "/photo.jpg/" }) {
              childImageSharp {
                  fixed(width: 200, height: 200, quality: 95) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          site {
              siteMetadata {
                  author {
                      name
                  }
                  social {
                      twitter
                  }
              }
          }
      }
  `);

  const author = data.site.siteMetadata?.author;

  const avatar = data?.avatar?.childImageSharp?.fixed;

  return (
    <div className="bio">

      <div>
        <Image
          fixed={avatar}
          alt={author?.name || ``}
          className="bio-avatar"
        />
        <SocialBar/>
      </div>

      <div>

        <p style={{marginBottom: '1rem'}}>
          Hi, I’m Roman Zaiats 👋
        </p>

        <p>
          I’m a developer, indie hacker and this is the place where I post things I find interesting
        </p>
      </div>
    </div>
  );
};

export default Bio;
