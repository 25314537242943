import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';
import TwitterIcon from './icons/twitter.svg';
import GitHubIcon from './icons/github.svg';
import LinkedInIcon from './icons/linkedin.svg';
import './social-bar.css';

const Icon = ({icon, url}) => (
  <a className="social-bar__link mr-8" rel="noopener noreferrer" target="__blank" href={url}>
    <img src={icon} className="social-bar__icon" alt={url}/>
  </a>
);

Icon.propTypes = {
  icon : PropTypes.string.isRequired,
  url  : PropTypes.string.isRequired,
  scale: PropTypes.number
};

const SocialBar = () => {
  const data = useStaticQuery(graphql`
      query SocialBarQuery {
          site{
              siteMetadata {
                  social{
                      twitter
                      github
                      linkedIn
                  }
              }
          }
      }
  `);

  return (
    <ul className="flex flex-row justify-start w-full p-0 list-reset my-4 mx-0">
      <li>
        <Icon
          url={`//linkedin.com/in/${data.site.siteMetadata.social.linkedIn}`}
          icon={LinkedInIcon}
        />
      </li>
      <li>
        <Icon
          url={`//twitter.com/${data.site.siteMetadata.social.twitter}`}
          icon={TwitterIcon}
        />
      </li>
      <li>
        <Icon
          url={`//github.com/${data.site.siteMetadata.social.github}`}
          icon={GitHubIcon}
        />
      </li>
    </ul>
  );
};

export default SocialBar;
